import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import {BrowserRouter as Router, Route, Link, Routes} from "react-router-dom";
import LoSientoNorita from './Pages/loSientoNorita';
import CuentaRegresiva from "./Pages/countdown/cuentaRegresiva";

Amplify.configure(config);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route exact path="/" element={<App/>}/>
                <Route exact path="losientonorita" element={<LoSientoNorita/>}/>
                <Route exact path="viaje"
                       element={<CuentaRegresiva timeTillDate="07 28 2023, 12:00 am" timeFormat="MM DD YYYY, h:mm a"/>
                       }/>
            </Routes>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
