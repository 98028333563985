import React from 'react';
import moment from 'moment';

class CuentaRegresiva extends React.Component {
    state = {
        days: undefined,
        hours: undefined,
        minutes: undefined,
        seconds: undefined
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            const then = moment("28/07/2023", "DD/MM/YYYY");
            const timeLeft = moment(then.diff(moment()));

            const days = then.diff(moment(), 'days');
            const hours = then.diff(moment(), 'hours');
            const minutes = then.diff(moment(), 'minutes');
            const seconds = timeLeft.format('ss')
            this.setState({days, hours, minutes, seconds});
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const {days, hours, minutes, seconds} = this.state;
        const then = moment("28/07/2023", "DD/MM/YYYY");
        const timeLeft = moment().diff(then, 'minutes')

        // Mapping the date values to radius values
        const daysRadius = mapNumber(days, 33, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);
        if (!seconds) {
            return null;
        }

        return (
            <div>
                <h1 className='textCool'>Feliz Cumplea&ntilde;os Isabel</h1>
                { <div className="countdown-wrapper">
                    {(
                        <div className="countdown-item">
                            <SVGCircle radius={daysRadius}/>
                            {days}
                            <span>days</span>
                        </div>
                    )}
                    {(
                        <div className="countdown-item">
                            <SVGCircle radius={hoursRadius}/>
                            {hours}
                            <span>hours</span>
                        </div>
                    )}
                    {(
                        <div className="countdown-item">
                            <SVGCircle radius={daysRadius}/>
                            {0}
                            <span>minutes</span>
                        </div>
                    )}

                    {(
                        <div className="countdown-item">
                            <SVGCircle radius={daysRadius}/>
                            {0}
                            <span>seconds</span>
                        </div>
                    )}
                </div>}
                {timeLeft >= 0 && <div className="textCenter">
                    <p className='textCool fs-40'>
                        Alista maletas, prep&aacute;rate, que nos vamos para
                        <br/>
                         COLONIA SANT JORDI!!
                    </p>
                    <br/>
                    <p className='textCool fs-20'>
                        Por si no sabes donde es, pista (es en Mallorca)
                    </p>
                </div>}
                <div className='textCenter'>
                    <p className='textCool fs-20'>
                        Se acerca uno de los d&iacute;as más emocionantes para los dos: tu cumplea&ntilde;os. Y para
                        celebrarlo de
                        una manera especial, ¡nos vamos de viaje!
                    </p>
                    <p className='textCool fs-20'>
                        Ya es nuestro segundo cumplea&ntilde;os juntos, es muy emocionante compartir nuevos destinos y
                        aventuras pero sobre todo, seguir en esta aventura diaria que es la vida, espero que sigamos
                        acumulando memorias y que nuestra pared de fotos cada vez crezca m&aacute;s!
                    </p>
                    <p className='textCool fs-20'>
                        Me voy de viaje con el amor de mi vida, de subida y de bajada ✈️🏖️⛱️🧡!
                    </p>
                    <p className='textCool fs-20'>
                        El destino ser&aacute; revelado en cuanto este contador llegue a cero. Pero mientras tanto,
                        aseg&uacute;rate
                        de tener en tu lista lo siguiente:
                    </p>
                    <p className='textCool fs-20'>
                        <ul>
                            <li>Vestido de ba&ntilde;o 👙</li>
                            <li>Gafas de sol 😎</li>
                            <li>Crema Antisolar ☀️</li>
                        </ul>
                    </p>

                    <p className='textCool fs-20'>
                        ¡Prep&aacute;rate para una aventura inolvidable en tu cumplea&ntilde;os!
                    </p>

                    <p className='textCool fs-20'>
                        Te amo mucho,
                        <br/>
                        Diego 🐜!
                    </p>
                </div>
                <img src="/airplane.png" className='fix' alt="image"/>
            </div>
        );
    }
}

const SVGCircle = ({radius}) => (
    <svg className="countdown-svg">
        <path
            fill="none"
            stroke="#800080"
            stroke-width="4"
            d={describeArc(50, 50, 48, 0, radius)}
        />
    </svg>
);

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    var d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');

    return d;
}

function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (
        ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
}

export default CuentaRegresiva;